import React from 'react'
import Layout, { MenuList } from 'screens/shared/Layout'
import Head from 'components/modules/Head'
import Search from 'screens/shared/Search'
import { container } from 'components/core/Layout'
import { HitCards } from 'components/modules/Hits'
import { Box, Flex, Card } from 'components/primitives'
import { graphql } from 'gatsby'
import Heading from 'components/core/Heading'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export default ({
  data: {
    collection: { data }
  },
  ...props
}) => {
  const matches = useMediaQuery('(min-width:640px)')

  return (
    <Layout>
      <Head title={data.name} />
      <Flex flexWrap='wrap' {...container}>
        {matches && (
          <Box flexGrow={1} flexBasis={256}>
            <MenuList />
          </Box>
        )}
        <Box flexGrow={99999} flexBasis={0}>
          <Card display='flex' mb={4} bg='text'>
            <Heading
              mr={[4, 5, 6]}
              my={5}
              ml='auto'
              fontSize={4}
              fontWeight='heavy'
              color='background'
            >
              {data.handle !== 'all' ? `${data.name} 타일` : data.name}
            </Heading>
          </Card>
          <Search pageData={data} location={props.location} hitsPerPage={16}>
            <HitCards />
          </Search>
        </Box>
      </Flex>
    </Layout>
  )
}

export const query = graphql`
  query Collection($handle: String!) {
    collection: airtable(
      table: { eq: "컬렉션" }
      data: { handle: { eq: $handle } }
    ) {
      data {
        name
        handle
        description
      }
    }
    allCollections: allAirtable(
      filter: { table: { eq: "컬렉션" } }
      sort: { fields: data___order }
    ) {
      edges {
        node {
          data {
            name
            handle
          }
        }
      }
    }
  }
`
